import React, { Component } from 'react';
import { Upload, Icon, Form, Input, message, Button, notification, Select, Tag, Tooltip, Checkbox } from 'antd';
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});
const { Option } = Select;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}

class PublishEdit extends Component {
    state = {
        tags: [],
        tagInputVisible: false,
        tagInputValue: '',
        submitLoading: false,
        imageUrlSmall: '',
        imageUrlBig: '',
        isEdit: false,
        editInfo: {},
        content: '',
        photoNameSmall: '',
        photoNameBig: ''
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
    }
    componentDidMount() {
        const { isEdit, editInfo } = this.props;
        if (isEdit) {
            const { imageUrlBig, imageUrlSmall, content, tags } = editInfo;
            this.setState({
                isEdit: isEdit,
                editInfo: editInfo,
                imageUrlSmall: imageUrlSmall,
                imageUrlBig: imageUrlBig,
                tags: tags
            })
            this.props.form.setFieldsValue({
                content: BraftEditor.createEditorState(content)
            })
        }
    }
    //tags controls
    tagClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    };
    tagInput = () => {
        this.setState({ tagInputVisible: true }, () => this.input.focus());
    };
    tagInputChange = e => {
        this.setState({ tagInputValue: e.target.value });
    };
    tagInputConfirm = () => {
        const { tagInputValue } = this.state;
        let { tags } = this.state;
        if (tagInputValue && tags.indexOf(tagInputValue) === -1) {
            tags = [...tags, tagInputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            tagInputVisible: false,
            tagInputValue: '',
        });
    };
    tagSaveInputRef = input => (this.input = input);

    smallPicChange = info => {
        if (info.file.status === 'uploading') {
            console.log(info)
            this.setState({ imgLoadingSmall: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlSmall =>
                this.setState({
                    imageUrlSmall,
                    imgLoadingSmall: false,
                    photoNameSmall: info.file.originFileObj.name
                }),
            );
        }
    };
    bigPicChange = info => {
        if (info.file.status === 'uploading') {
            console.log(info)
            this.setState({ imgLoadingBig: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlBig =>
                this.setState({
                    imageUrlBig,
                    imgLoadingBig: false,
                    photoNameBig: info.file.originFileObj.name
                }),
            );
        }
    };
    handleSubmit = (e) => {
        const { imageUrlSmall, imageUrlBig, photoNameSmall, photoNameBig, isEdit, editInfo } = this.state;
        e.preventDefault();
        this.setState({
            submitLoading: true
        })
        const that = this;
        if (imageUrlSmall && imageUrlBig) {
            this.props.form.validateFields((err, values) => {
                if (err) throw err;
                const content = values.content.toHTML();
                const sendValue = {
                    title: values.title,
                    weights: values.weights,
                    relatedEmail: values.relatedEmail,
                    project: values.project,
                    exclusive: values.exclusive,
                    date: values.date,
                    content: content,
                    tags: that.state.tags,
                    imageUrlSmall: imageUrlSmall,
                    imageUrlBig: imageUrlBig,
                    photoNameSmall: photoNameSmall,
                    photoNameBig: photoNameBig,
                    isVideo: values.isVideo,
                    videoLink: values.videoLink
                }
                console.log(sendValue);
                if (isEdit) {
                    sendValue.id = editInfo._id;
                    instance.post('/api/articlesServer/article/edit', {
                        articleInfo: sendValue
                    })
                        .then(function (response) {
                            if (response.data.msg === "success") {
                                that.setState({
                                    submitLoading: false
                                });
                                notification.open({
                                    message: '提示',
                                    description:
                                        '修改成功'
                                });
                                that.props.toList();
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    instance.post('/api/articlesServer/article/add', {
                        articleInfo: sendValue
                    })
                        .then(function (response) {
                            if (response.data.msg === "success") {
                                that.setState({
                                    submitLoading: false
                                });
                                notification.open({
                                    message: '提示',
                                    description:
                                        '发布成功'
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });
        } else {
            notification.open({
                message: '提示',
                description:
                    '请上传文章图片'
            });
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { tags, tagInputVisible, tagInputValue, submitLoading, imageUrlSmall, imageUrlBig, isEdit, editInfo } = this.state;
        const { title, date, weights, relatedEmail, project, exclusive, isVideo, videoLink } = isEdit ? editInfo : '';
        const uploadButtonSmall = (
            <div>
                <Icon type={this.state.imgLoadingSmall ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadButtonBig = (
            <div>
                <Icon type={this.state.imgLoadingBig ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="blocks">
                {isEdit ?
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div className="model-title">Edit Article</div>
                        <div onClick={() => { this.props.toList() }} style={cancel}>Cancel</div>
                    </div>
                    : <div className="model-title">Upload Article</div>
                }
                <div className="flex" style={{ marginTop: 20 }}>
                    <Upload
                        name="articleSmallPic"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action='https://my-express-project-6ahfsb4pf.vercel.app/api/cmsServer/uploadImg'
                        beforeUpload={beforeUpload}
                        onChange={this.smallPicChange}
                    >
                        {imageUrlSmall ? <img src={imageUrlSmall} style={imageStyle} alt="avatar" /> : uploadButtonSmall}
                    </Upload>
                    <Upload
                        name="articleBigPic"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action='https://my-express-project-6ahfsb4pf.vercel.app/api/cmsServer/uploadImg'
                        beforeUpload={beforeUpload}
                        onChange={this.bigPicChange}
                    >
                        {imageUrlBig ? <img src={imageUrlBig} style={imageStyle} alt="avatar" /> : uploadButtonBig}
                    </Upload>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item style={{ marginBottom: '12px' }} label="Title">
                        {getFieldDecorator('title', {
                            rules: [
                                {
                                    required: true,
                                    message: "Please input article's title",
                                },
                            ],
                            initialValue: title
                        })(<Input placeholder="Please input article's title" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Publish Date">
                        {getFieldDecorator('date', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input publish date(example: July 12th, 2019)',
                                },
                            ],
                            initialValue: date
                        })(<Input placeholder="Please input publish date(example: July 12th, 2019)" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Related Account(Email)">
                        {getFieldDecorator('relatedEmail', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input related account(email)',
                                },
                            ],
                            initialValue: relatedEmail
                        })(<Input placeholder="Please input related account(email)" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Weights">
                        {getFieldDecorator('weights', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input article weights',
                                },
                            ],
                            initialValue: weights
                        })(<Input type="number" placeholder="Please input article weights" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Project">
                        {getFieldDecorator('project', {
                            rules: [
                                { required: true, message: 'Please select project' },
                            ],
                            initialValue: project
                        })(
                            <Select placeholder="Please select project">
                                <Option value='rpwchina'>RPWChina</Option>
                                <Option value='rpwasia'>RPWAsia</Option>
                                <Option value='rpwthailand'>RPWThailand</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Tags">
                        {getFieldDecorator('tags', {
                            rules: [
                                {
                                    required: true
                                },
                            ],
                            initialValue: 'tag'
                        })(<div></div>)}
                        {tags.map((tag, index) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                                <Tag key={tag} closable={true} onClose={() => this.tagClose(tag)}>
                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </Tag>
                            );
                            return isLongTag ? (
                                <Tooltip title={tag} key={tag}>
                                    {tagElem}
                                </Tooltip>
                            ) : (
                                    tagElem
                                );
                        })}
                        {tagInputVisible && (
                            <Input
                                ref={this.tagSaveInputRef}
                                type="text"
                                size="small"
                                style={{ width: 78 }}
                                value={tagInputValue}
                                onChange={this.tagInputChange}
                                onBlur={this.tagInputConfirm}
                                onPressEnter={this.tagInputConfirm}
                            />
                        )}
                        {!tagInputVisible && (
                            <Tag onClick={this.tagInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                <Icon type="plus" /> New Tag
          </Tag>
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Content">
                        {getFieldDecorator('content', {
                            validateTrigger: 'onBlur',
                            rules: [{
                                required: true,
                                validator: (_, value, callback) => {
                                    if (value.isEmpty()) {
                                        callback("Content is Required")
                                    } else {
                                        callback()
                                    }
                                }
                            }],
                        })(
                            <BraftEditor
                                style={backgroundEditor}
                                placeholder="Content Area"
                                contentStyle={backgroundEditorContent}
                            />
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Is Article Exclusive?">
                        {getFieldDecorator('exclusive', {
                            valuePropName: 'checked',
                            initialValue: exclusive,
                        })(<Checkbox>Exclusive</Checkbox>)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Is This a Video?">
                        {getFieldDecorator('isVideo', {
                            valuePropName: 'checked',
                            initialValue: isVideo,
                        })(<Checkbox>Video</Checkbox>)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label="Video Link">
                        {getFieldDecorator('videoLink', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input Video Link',
                                },
                            ],
                            initialValue: videoLink
                        })(<Input placeholder="Please input Video Link" />)}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={submitLoading}>
                            Submit
          </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
const backgroundEditor = {
    border: '1px solid #d1d1d1',
    borderRadius: 5
}
const backgroundEditorContent = {
    height: 200
}
const imageStyle = {
    width: 100,
    height: 85
}
const cancel = {
    marginLeft: 10,
    color: '#6f6f6f',
    textDecoration: 'underline',
    fontSize: 16,
    cursor: 'pointer'
}
const ContentPublishEdit = Form.create({ name: 'ContentPublishEdit' })(PublishEdit);

export default ContentPublishEdit;