import React, { Component } from 'react';
import { Select, Form, Input, Modal, Button } from 'antd';
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});
const { Option } = Select;

class AddEdit extends Component {
    state = {
        loading: false,
        visible: this.props.isAddEdit,
        isAdd: this.props.isAdd,
        editInfo: this.props.editInfo
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.isAddEdit,
            isAdd: nextProps.isAdd,
            editInfo: nextProps.editInfo
        });
    }
    //添加Member
    addSend = () => {
        const { form } = this.props;
        const that = this;
        this.setState({
            loading: true
        })
        form.validateFields((err, values) => {
            const that2 = this;
            if (err) {
                return;
            }
            values.applyType = 0;
            console.log(values);
            instance.post('/api/members/create', {
                memberInfo: values
            })
                .then(function (response) {
                    if (response.data.msg === "success") {
                        that.setState({
                            loading: false
                        });
                        form.resetFields();
                        that2.props.addClose();
                        that2.props.reload();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }
    //编辑  Member
    addEdit = () => {
        console.log(this);
        const { form } = this.props;
        const that = this;
        this.setState({
            loading: true
        })
        form.validateFields((err, values) => {
            const that2 = this;
            if (err) {
                return;
            }
            values.applyType = 0;
            values._id = this.state.editInfo._id;
            console.log(values);
            instance.post('/api/members/edit', {
                memberInfo: values
            })
                .then(function (response) {
                    if (response.data.msg === "success") {
                        that.setState({
                            loading: false
                        });
                        form.resetFields();
                        that2.props.addClose();
                        that2.props.reload();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    render() {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { addClose } = this.props;
        const { memberName, memberEmail, memberCompany, memberJobTitle, memberMobile, memberLevel, event } = this.state.isAdd ? '' : this.state.editInfo;
        const memberLevelArray = ['Visitor', 'Member', 'Delegate', 'Exhibitor', 'Sponsor'];
        const memberLevelText = memberLevel !== '' ? memberLevelArray[memberLevel] : [];
        return (
            <Modal
                visible={visible}
                title="Add Member"
                onOk={this.addSend}
                onCancel={addClose.bind(this)}
                footer={[
                    <Button key="back" onClick={addClose.bind(this)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={this.state.isAdd ? this.addSend : this.addEdit}>
                        {this.state.isAdd ? 'Add' : 'Edit'}
                    </Button>
                ]}
                destroyOnClose={true}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Member\'s Name',
                                },
                            ],
                            initialValue: memberName
                        })(<Input placeholder="Please input Member's Name" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberCompany', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Member\'s Company',
                                },
                            ],
                            initialValue: memberCompany
                        })(<Input placeholder="Please input Member's Company" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberJobTitle', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Member\'s Job Title',
                                },
                            ],
                            initialValue: memberJobTitle
                        })(<Input placeholder="Please input Member's Job Title" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberEmail', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid Email',
                                },
                                {
                                    required: true,
                                    message: 'Please input Member\'s Email',
                                },
                            ],
                            initialValue: memberEmail
                        })(<Input placeholder="Please input Member's Email" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberMobile', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Member\'s Mobile!',
                                },
                            ],
                            initialValue: memberMobile
                        })(<Input placeholder="Please input Member's Mobile" />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('event', {
                            rules: [
                                { required: false },
                            ],
                            initialValue: event
                        })(
                            <Select mode="multiple" placeholder="Please Select Events Member had or will Attend">
                                <Option value="China2019">China2019</Option>
                                <Option value="Thailand2020">Thailand2020</Option>
                                <Option value="Indonesia2020">Indonesia2020</Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }}>
                        {getFieldDecorator('memberLevel', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select member\'s Level',
                                },
                            ],
                            initialValue: memberLevelText
                        })(
                            <Select placeholder="Please Select Member's Level">
                                <Option value="Visitor">Visitor</Option>
                                <Option value="Member">Member</Option>
                                <Option value="Delegate">Delegate</Option>
                                <Option value="Exhibitor">Exhibitor</Option>
                                <Option value="Sponsor">Sponsor</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const WrappedAddEditForm = Form.create({ name: 'AddEdit' })(AddEdit);

export default WrappedAddEditForm;