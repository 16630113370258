import React, { Component } from 'react';
import SpeakerList from './SpeakerList';
import MainForm from './MainForm'
import SponsorList from './SponsorList';

class App extends Component {
    state = {
        event: this.props.event
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            event: nextProps.event,
        });
    }

    render() {
        return (
            <div>
                <div style={blocks} >
                    <SpeakerList event={this.state.event}></SpeakerList>
                </div>
                <div style={blocks} >
                    <SponsorList event={this.state.event}></SponsorList>
                </div>
                <div style={blocks}>
                    <MainForm event={this.state.event}></MainForm>
                </div>
            </div>
        );
    }
}

const blocks = {
    margin: '24px 16px',
    padding: 24,
    background: '#fff'
}

export default App;