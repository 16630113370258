import React, { Component } from 'react';
import { Form, Icon, Input, Button, notification } from 'antd';
import '../../App.css';
import axios from 'axios';
import rdLogin from '../../redux/rdLogin';
import rpwleft from '../../rpwlogocoloricon.png';
import rpwright from '../../rpwright.png';
import md5 from 'md5';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});

class NormalLoginForm extends Component {
    state = {
        loginStatus: this.props.loginStatus,
    }
    componentDidMount() {
        rdLogin.loginStore.subscribe(() => {
            this.setState({
                loginStatus: rdLogin.loginStore.getState().loginStatus
            })
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) throw err;
            const password = md5(values.password);
            instance.post('/api/manageServer/login', {
                account: values.username,
                password: password
            })
                .then(function (response) {
                    if (response.data.msg === "success") {
                        rdLogin.loginStore.dispatch(rdLogin.createLogger(response.data.account));
                        console.log(rdLogin.loginStore.getState());
                    } else {
                        notification.open({
                            message: '提示',
                            description:
                                '用户名密码错误。'
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={this.state.loginStatus ? { display: 'none' } : {}} className="center-element flex-column full-page">
                <div className='logo-wrap'>
                    <img src={rpwleft} alt='rpwLogo' className='logo-small' />
                    <img src={rpwright} alt='rpwLogo' className='logo-right' />
                </div>
                <Form onSubmit={this.handleSubmit} className="login-form" style={{ maxWidth: '300px' }}>
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Username"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Password"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;

