import { createStore } from 'redux';

// Manager come to loggin(Action Creators)
const createLogger = (account) => {
    return {
        type: 'LOGGER',
        payload: {
            account: account
        }
    }
}

const initState = {
    account: 'none',
    loginStatus: false
}

const login = (state = initState, action = {}) => {
    switch (action.type) {
        case 'LOGGER':
            return { ...state, loginStatus: true, account: action.payload.account };
        default:
            return state;
    }
}

const loginStore = createStore(login);

export default { createLogger, loginStore };