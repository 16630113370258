import React, { Component } from 'react';
import { Modal, Button } from 'antd';

//定义申请类型
const applyType = ['No Apply', 'Member', 'Delegate', 'Exhibitor', 'Sponsor', 'Download Post Report', 'Download Agenda', 'BLFC2021 Pink Sheet', 'BLFC2021 Scrip'];
//定义用户级别
const memberLevel = ['Visitor', 'Member', 'Delegate', 'Exhibitor', 'Sponsor'];

class CheckRequest extends Component {
    state = {
        loading: false,
        visible: this.props.isCheckRequest,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.isCheckRequest,
            loading: nextProps.loading
        });
    }

    render() {
        const { visible, loading } = this.state;
        console.log(this.props.currentCheckRequest);
        const { applyBeMember, interestedProject, requestTime, informaConfirm, poseidanConfirm } = this.props.currentCheckRequest;
        let requestTimeReadable = '';
        if (requestTime) {
            const requestTimeStamp = new Date(requestTime);
            requestTimeReadable = requestTimeStamp.getFullYear() + '/' + requestTimeStamp.getMonth() + '/' + requestTimeStamp.getDate() + '/' + requestTimeStamp.getHours() + ':' + requestTimeStamp.getMinutes() + ':' + requestTimeStamp.getSeconds();
        }
        return (
            <div>
                <Modal
                    visible={visible}
                    title="About Member"
                    onOk={this.props.handleApprove.bind(this)}
                    onCancel={this.props.handleClose.bind(this)}
                    footer={applyBeMember ? [
                        <Button key="back" loading={loading === 2 ? true : false} onClick={this.props.handleDecline.bind(this)}>
                            Decline
            </Button>,
                        <Button key="submit" type="primary" loading={loading === 1 ? true : false} onClick={this.props.handleApprove.bind(this)}>
                            Approve
            </Button>,
                    ] : null}
                >
                    <p>Member Level: {memberLevel[this.props.currentCheckRequest.memberLevel]}</p>
                    <p>Apply Type: {applyType[this.props.currentCheckRequest.applyType]}</p>
                    <p>Apply to Be a Member: {applyBeMember ? 'Yes' : 'No'}</p>
                    <p>Interested Project: {interestedProject}</p>
                    <p>requestTime: {requestTimeReadable}</p>
                    <p>Informa Checked: {informaConfirm?"True":"False"}</p>
                    <p>Poseidan Checked: {poseidanConfirm?"True":"False"}</p>
                </Modal>
            </div>
        );
    }
}

export default CheckRequest;