import React, { Component } from 'react';
import { Icon, Form, Input, Button, notification, Row, Col, Select, Spin } from 'antd';
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});
const { Option } = Select;

class MainForm extends Component {
    state = {
        event: this.props.event,
        eventinfo: {},
        loading: false,
        collapse: false,
        editLoading: false,
        SponsorSortList: []
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.event !== this.state.event) {
            this.setState({
                event: nextProps.event,
            });
            this.getEventInfo(nextProps.event);
        }
    }
    componentDidMount() {
        const { event } = this.state;
        this.getEventInfo(event);
    }
    getEventInfo = event => {
        const that = this;
        this.setState({
            loading: true
        })
        instance.post('/api/cmsServer/event/info', {
            abbrev: event
        })
            .then(function (response) {
                if (response.data.msg === "success") {
                    that.setState({
                        eventinfo: response.data.eventInfo,
                        SponsorSortList: response.data.eventInfo.SponsorSortList,
                        loading: false
                    });
                    that.props.form.setFieldsValue({
                        background: BraftEditor.createEditorState(response.data.eventInfo.background),
                        whatsNewPara2: BraftEditor.createEditorState(response.data.eventInfo.whatsNew.para2),
                        whatsNewPara3: BraftEditor.createEditorState(response.data.eventInfo.whatsNew.para3),
                        whatsNewPara4: BraftEditor.createEditorState(response.data.eventInfo.whatsNew.para4),
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            editLoading: true
        })
        const that = this;
        this.props.form.validateFields((err, values) => {
            console.log(values);
            if (err) throw err;
            const background = values.background.toHTML();
            const whatsNewPara2 = values.whatsNewPara2.toHTML();
            const whatsNewPara3 = values.whatsNewPara3.toHTML();
            const whatsNewPara4 = values.whatsNewPara4.toHTML();
            const sendValue = {
                abbrev: that.state.event,
                name: values.name,
                time: values.time,
                location: values.location,
                topHighlight: values.topHighlight,
                brief: values.brief,
                theme: values.theme,
                background: background,
                numbers: [
                    {
                        type: values.numberType0,
                        num: values.numberNumber0
                    },
                    {
                        type: values.numberType1,
                        num: values.numberNumber1
                    },
                    {
                        type: values.numberType2,
                        num: values.numberNumber2
                    },
                    {
                        type: values.numberType3,
                        num: values.numberNumber3
                    }
                ],
                sponsorsHighlight: values.sponsorsHighlight,
                sponsorTypes: values.sponsorTypes,
                whatsNew: {
                    title1: values.whatsNewTitle1,
                    para1: values.whatsNewPara1,
                    title2: values.whatsNewTitle2,
                    para2: whatsNewPara2,
                    img2: values.whatsNewImg2,
                    title3: values.whatsNewTitle3,
                    para3: whatsNewPara3,
                    img3: values.whatsNewImg3,
                    title4: values.whatsNewTitle4,
                    para4: whatsNewPara4,
                    img4: values.whatsNewImg4
                },
                agendaLink: values.agendaLink,
                agendaSnapshots: [
                    {
                        title: values.snapshotsTitle0,
                        content: values.snapshotsContent0
                    }, {
                        title: values.snapshotsTitle1,
                        content: values.snapshotsContent1
                    }, {
                        title: values.snapshotsTitle2,
                        content: values.snapshotsContent2
                    }, {
                        title: values.snapshotsTitle3,
                        content: values.snapshotsContent3
                    }, {
                        title: values.snapshotsTitle4,
                        content: values.snapshotsContent4
                    }, {
                        title: values.snapshotsTitle5,
                        content: values.snapshotsContent5
                    }
                ]
            };
            instance.post('/api/cmsServer/event/edit', {
                eventInfo: sendValue
            })
                .then(function (response) {
                    if (response.data.msg === "success") {
                        that.setState({
                            eventinfo: response.data.eventInfo,
                            editLoading: false
                        });
                        notification.open({
                            message: '提示',
                            description:
                                '修改成功'
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    //折叠
    folder = () => {
        this.setState({
            collapse: this.state.collapse ? false : true
        })
    }
    renderNumbers = (numbers) => {
        const list = [];
        const { getFieldDecorator } = this.props.form;
        // eslint-disable-next-line
        numbers.map((item, index) => {
            list.push(<Col key={index} span={12}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item style={{ marginBottom: '12px' }} label={`Number ${index + 1} Name`}>
                            {getFieldDecorator('numberType' + index, {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input Number ' + (index + 1) + ' Name',
                                    },
                                ],
                                initialValue: item.type
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item style={{ marginBottom: '12px' }} label={`Number ${index + 1} Value`}>
                            {getFieldDecorator('numberNumber' + index, {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input Number ' + (index + 1) + ' Value',
                                    },
                                ],
                                initialValue: item.num
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                </Row>
            </Col>)
        })
        return list;
    }
    renderAgendaSnapshots = (snapshots) => {
        const list = [];
        const { getFieldDecorator } = this.props.form;
        // eslint-disable-next-line
        snapshots.map((item, index) => {
            list.push(
                <div key={index}>
                    <Form.Item style={{ marginBottom: '12px' }} label={`Snapshots ${index + 1} Title`}>
                        {getFieldDecorator('snapshotsTitle' + index, {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Snapshots ' + (index + 1) + ' Title',
                                },
                            ],
                            initialValue: item.title
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '12px' }} label={`Snapshots ${index + 1} Content`}>
                        {getFieldDecorator('snapshotsContent' + index, {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Snapshots ' + (index + 1) + ' Content',
                                },
                            ],
                            initialValue: item.content
                        })(<Input />)}
                    </Form.Item>
                </div>
            )
        })
        return list;
    }
    sortTypes = (SponsorSortList) => {
        const list = [];
        // eslint-disable-next-line
        SponsorSortList.map((item, index) => {
            list.push(<Option key={index} value={item}>{item}</Option>)
        })
        return list;
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { name, time, location, topHighlight, brief, theme, sponsorsHighlight, agendaLink } = this.state.eventinfo ? this.state.eventinfo : '';
        const numbers = this.state.eventinfo.numbers ? this.state.eventinfo.numbers : [{ type: '', num: '' }, { type: '', num: '' }, { type: '', num: '' }];
        const whatsNew = this.state.eventinfo.whatsNew ? this.state.eventinfo.whatsNew : { title1: '', para1: '', title2: '', para2: '', img2: '', title3: '', para3: '', img3: '', title4: '', para4: '', img4: '' };
        const snapshots = this.state.eventinfo.agendaSnapshots ? this.state.eventinfo.agendaSnapshots : [{ title: '', content: '' }, { title: '', content: '' }, { title: '', content: '' }, { title: '', content: '' }, { title: '', content: '' }, { title: '', content: '' }];
        const { sponsorTypes } = this.state.eventinfo ? this.state.eventinfo : [];
        const { collapse, editLoading, SponsorSortList, loading } = this.state;
        const controls = ['bold', 'italic', 'underline', 'text-color']
        return (
            <div>
                <div className='flex-align-center' style={{ marginBottom: 20 }}>
                    <div className="model-title">Edit Event Infomation</div>
                    <Icon type={collapse ? "up" : "down"} style={{ cursor: 'pointer' }} onClick={() => { this.folder() }} />
                </div>
                <Spin spinning={loading}>
                    <Form onSubmit={this.handleSubmit} style={collapse ? {} : { display: 'none' }}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s name',
                                            },
                                        ],
                                        initialValue: name
                                    })(<Input placeholder="Please input event's name" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Time">
                                    {getFieldDecorator('time', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s time',
                                            },
                                        ],
                                        initialValue: time
                                    })(<Input placeholder="Please input event's time" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Location">
                                    {getFieldDecorator('location', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s location',
                                            },
                                        ],
                                        initialValue: location
                                    })(<Input placeholder="Please input event's location" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Top Highlight">
                                    {getFieldDecorator('topHighlight', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s Highlight',
                                            },
                                        ],
                                        initialValue: topHighlight
                                    })(<Input placeholder="Please input event's Highlight" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Brief">
                                    {getFieldDecorator('brief', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s brief',
                                            },
                                        ],
                                        initialValue: brief
                                    })(<Input placeholder="Please input event's brief" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item style={{ marginBottom: '12px' }} label="Theme">
                                    {getFieldDecorator('theme', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input event\'s theme',
                                            },
                                        ],
                                        initialValue: theme
                                    })(<Input placeholder="Please input event's theme" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Background">
                            {getFieldDecorator('background', {
                                validateTrigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    validator: (_, value, callback) => {
                                        if (value.isEmpty()) {
                                            callback('Please input event\'s background')
                                        } else {
                                            callback()
                                        }
                                    }
                                }],
                            })(
                                <BraftEditor
                                    style={backgroundEditor}
                                    controls={controls}
                                    placeholder="Please input event's background"
                                    contentStyle={backgroundEditorContent}
                                />
                            )}
                        </Form.Item>
                        <Row gutter={24}>{this.renderNumbers(numbers)}</Row>
                        <Form.Item style={{ marginBottom: '12px' }} label="Highlight Sponsor Types">
                            {getFieldDecorator('sponsorsHighlight', {
                                rules: [
                                    { required: true, message: 'Please Select Highlight Sponsor Type' },
                                ],
                                initialValue: sponsorsHighlight
                            })(
                                <Select placeholder="Please Select Highlight Sponsor Type">
                                    {this.sortTypes(SponsorSortList)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="Sort Sponsor Types">
                            {getFieldDecorator('sponsorTypes', {
                                rules: [
                                    { required: true, message: 'Please Sort Sponsor Types', type: 'array' },
                                ],
                                initialValue: sponsorTypes
                            })(
                                <Select mode="multiple" placeholder="Please Sort Sponsor Types">
                                    {this.sortTypes(SponsorSortList)}
                                </Select>
                            )}
                        </Form.Item>
                        <div className="model-title">Edit What's New</div>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Top Title">
                            {getFieldDecorator('whatsNewTitle1', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Top Title",
                                    },
                                ],
                                initialValue: whatsNew.title1
                            })(<Input placeholder="Please input What's New Top Title" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Top Content">
                            {getFieldDecorator('whatsNewPara1', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Top Content",
                                    },
                                ],
                                initialValue: whatsNew.para1
                            })(<Input placeholder="Please input What's New Top Content" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Title 2">
                            {getFieldDecorator('whatsNewTitle2', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Title 2",
                                    },
                                ],
                                initialValue: whatsNew.title2
                            })(<Input placeholder="Please input What's New Title" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Content 2">
                            {getFieldDecorator('whatsNewPara2', {
                                validateTrigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    validator: (_, value, callback) => {
                                        if (value.isEmpty()) {
                                            callback("Please input What's New Content 2")
                                        } else {
                                            callback()
                                        }
                                    }
                                }],
                            })(
                                <BraftEditor
                                    style={backgroundEditor}
                                    controls={controls}
                                    placeholder="Please input What's New Content 2"
                                    contentStyle={backgroundEditorContent}
                                />
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Image Url 2">
                            {getFieldDecorator('whatsNewImg2', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Image Url 2",
                                    },
                                ],
                                initialValue: whatsNew.img2
                            })(<Input placeholder="Please input What's New Image Url 2" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Title 3">
                            {getFieldDecorator('whatsNewTitle3', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Title 3",
                                    },
                                ],
                                initialValue: whatsNew.title3
                            })(<Input placeholder="Please input What's New Title 3" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Content 3">
                            {getFieldDecorator('whatsNewPara3', {
                                validateTrigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    validator: (_, value, callback) => {
                                        if (value.isEmpty()) {
                                            callback("Please input What's New Content 3")
                                        } else {
                                            callback()
                                        }
                                    }
                                }],
                            })(
                                <BraftEditor
                                    style={backgroundEditor}
                                    controls={controls}
                                    placeholder="Please input What's New Content 3"
                                    contentStyle={backgroundEditorContent}
                                />
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Image Url 3">
                            {getFieldDecorator('whatsNewImg3', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Image Url 3",
                                    },
                                ],
                                initialValue: whatsNew.img3
                            })(<Input placeholder="Please input What's New Image Url 3" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Title 4">
                            {getFieldDecorator('whatsNewTitle4', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Title 4",
                                    },
                                ],
                                initialValue: whatsNew.title4
                            })(<Input placeholder="Please input What's New Title 4" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Content 4">
                            {getFieldDecorator('whatsNewPara4', {
                                validateTrigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    validator: (_, value, callback) => {
                                        if (value.isEmpty()) {
                                            callback("Please input What's New Content 4")
                                        } else {
                                            callback()
                                        }
                                    }
                                }],
                            })(
                                <BraftEditor
                                    style={backgroundEditor}
                                    controls={controls}
                                    placeholder="Please input What's New Content 4"
                                    contentStyle={backgroundEditorContent}
                                />
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }} label="What's New Image Url 4">
                            {getFieldDecorator('whatsNewImg4', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input What's New Image Url 4",
                                    },
                                ],
                                initialValue: whatsNew.img4
                            })(<Input placeholder="Please input What's New Image Url 4" />)}
                        </Form.Item>
                        <div className="model-title">Edit Agenda Snapshots</div>
                        <Form.Item style={{ marginBottom: '12px' }} label="Agenda Download Link">
                            {getFieldDecorator('agendaLink', {
                                rules: [
                                    {
                                        message: "Please input Agenda Download Link",
                                    },
                                ],
                                initialValue: agendaLink
                            })(<Input placeholder="Please input Agenda Download Link" />)}
                        </Form.Item>
                        {this.renderAgendaSnapshots(snapshots)}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={editLoading}>
                                Submit
          </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );
    }
}
const backgroundEditor = {
    border: '1px solid #d1d1d1',
    borderRadius: 5
}
const backgroundEditorContent = {
    height: 200
}
const CMSMainForm = Form.create({ name: 'CMSMainForm' })(MainForm);

export default CMSMainForm;