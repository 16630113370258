import React, { Component } from 'react';
import { Table, Button, Divider, Icon, Spin } from 'antd';
import CheckRequest from './CheckRequest';
import AddEdit from './AddEdit';
import axios from 'axios';

const { Column } = Table;
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});

class Lists extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false, // Reload按钮的加载状态
        currentCheckRequest: {}, //管理CheckRequest的数据和状态
        isCheckRequest: false,
        currentCheckRequestLoadStatus: 0,
        members: [],//members区域总数据
        formLoading: false,//members区加载状态
        searchInfo: this.props.searchInfo,//传入索引信息
        isAddEdit: false, //AddEdit模块打开状态
        isAdd: true,//是添加还是编辑
        editInfo: {}//编辑会员的基本信息
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        //查询数据更新监控
        const that = this;
        this.setState({ formLoading: true });
        const searchInfo = JSON.stringify(nextProps.searchInfo);
        console.log(searchInfo);
        instance.get('/api/members/' + searchInfo, {})
            .then(function (response) {
                that.setState({
                    searchInfo: nextProps.searchInfo,
                    members: response.data,
                    formLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //重新加载
    reload = () => {
        this.setState({ loading: true, formLoading: true });
        const that = this;
        const searchInfo = JSON.stringify(that.state.searchInfo);
        instance.get('/api/members/' + searchInfo, {})
            .then(function (response) {
                that.setState({
                    members: response.data,
                    loading: false,
                    formLoading: false
                });
                console.log(that.state);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //选择数据
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    //往CheckRequest传入数据
    invokeCheckRequest = (record) => {
        this.setState({
            currentCheckRequest: record,
            isCheckRequest: true
        });
    };

    //处理CheckRequest请求通过
    handleApprove = () => {
        const that = this;
        this.setState({
            currentCheckRequestLoadStatus: 1
        })
        instance.post('/api/members/approve', {
            id: that.state.currentCheckRequest._id
        })
            .then(function (response) {
                console.log(response)
                that.setState({
                    isCheckRequest: false,
                    currentCheckRequestLoadStatus: 0
                });
                that.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    //处理CheckRequest请求拒绝
    handleDecline = () => {
        const that = this;
        this.setState({
            currentCheckRequestLoadStatus: 2
        })
        instance.post('/api/members/decline', {
            id: that.state.currentCheckRequest._id
        })
            .then(function (response) {
                console.log(response)
                that.setState({
                    isCheckRequest: false,
                    currentCheckRequestLoadStatus: 0
                });
                that.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    //处理CheckRequest关闭窗口
    handleClose = () => {
        this.setState({
            isCheckRequest: false,
            currentCheckRequestLoadStatus: 0
        })
    };
    //删除Member
    delete = (id) => {
        const that = this;
        instance.delete('/api/members/delete/' + id, {})
            .then(function (response) {
                console.log(response)
                that.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    //删除多个Member
    deleteMany = () => {
        const that = this;
        const ids = this.state.selectedRowKeys;
        console.log(ids)
        instance.post('/api/members/deleteMany/', {
            ids: ids
        })
            .then(function (response) {
                that.setState({
                    selectedRowKeys: []
                })
                that.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    //打开添加页
    addOpen = () => {
        this.setState({
            isAddEdit: true,
            isAdd: true,
            editInfo: {}
        });
        console.log('open')
    }
    //关闭添加页
    addClose = () => {
        this.setState({
            isAddEdit: false
        })
    }
    //打开编辑页
    invokeAddEdit = (record) => {
        this.setState({
            isAddEdit: true,
            isAdd: false,
            editInfo: record
        })
    }

    //处理Single Email
    handleEmailSingle = (id) => {
        instance.post('/api/members/EmailSingle', {
            id: id
        })
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        // eslint-disable-next-line
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                {/*CheckRequest*/}
                <CheckRequest currentCheckRequest={this.state.currentCheckRequest} isCheckRequest={this.state.isCheckRequest} handleApprove={this.handleApprove} handleDecline={this.handleDecline} handleClose={this.handleClose} loading={this.state.currentCheckRequestLoadStatus}></CheckRequest>
                {/*AddEdit*/}
                <AddEdit isAddEdit={this.state.isAddEdit} isAdd={this.state.isAdd} editInfo={this.state.editInfo} addOpen={this.addOpen} addClose={this.addClose} addSend={this.addSend} reload={this.reload}></AddEdit>
                {/*General Actions*/}
                <div style={{ marginBottom: 16 }}>
                    <Button style={{ marginRight: 16 }} type="primary" onClick={this.addOpen}>
                        <Icon type="user-add" />Add
                    </Button>
                    <Button style={{ marginRight: 16 }} type="primary" disabled={!hasSelected} onClick={this.deleteMany}>
                        <Icon type="user-delete" />Delete
                    </Button>
                    <Button style={{ marginRight: 16 }} type="primary" disabled={!hasSelected}>
                        <Icon type="mail" />Email
                    </Button>
                    <Button style={{ marginRight: 16 }} type="primary" onClick={this.reload} loading={loading}>
                        <Icon type="reload" />Reload
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                    </span>
                </div>
                <Spin spinning={this.state.formLoading}>
                    <Table rowKey={(text) => {
                        return text._id
                    }} style={{ background: '#fff' }} rowSelection={rowSelection} dataSource={this.state.members}>
                        <Column title="Name" dataIndex="memberName" key="Name" />
                        <Column title="Job Title" dataIndex="memberJobTitle" key="Job Title" />
                        <Column title="Company" dataIndex="memberCompany" key="Company" />
                        <Column title="Email" dataIndex="memberEmail" key="Email" />
                        <Column title="Mobile" dataIndex="memberMobile" key="Mobile" />
                        <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <span>
                                    {/*eslint-disable-next-line*/}
                                    <a style={record.applyType === 0 ? { display: 'none' } : {}} href="javascript:;" onClick={() => { this.invokeCheckRequest(record) }}>Check Request</a>
                                    <Divider type="vertical" style={record.applyType === 0 ? { display: 'none' } : {}} />
                                    {/*eslint-disable-next-line*/}
                                    <a href="javascript:;" onClick={() => { this.invokeAddEdit(record) }}>Edit</a>
                                    <Divider type="vertical" />
                                    {/*eslint-disable-next-line*/}
                                    <a href="javascript:;" onClick={() => { this.delete(record._id) }}>Delete</a>
                                    <Divider type="vertical" />
                                    {/*eslint-disable-next-line*/}
                                    <a href="javascript:;" onClick={() => { this.handleEmailSingle(record._id) }}>Email</a>
                                </span>
                            )}
                        />
                    </Table>
                </Spin>
            </div>
        );
    }
}

export default Lists;