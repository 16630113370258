import React, { Component } from 'react';
import { List, notification } from 'antd';
import PublishEdit from './PublishEdit';
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});

class ArticleList extends Component {
    state = {
        initLoading: true,
        articleList: [],
        showEdit: false,
        editInfo: ''
    };

    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        const that = this;
        instance.get('/api/articlesServer/article/getList')
            .then(function (response) {
                if (response.data.msg === "success") {
                    that.setState({
                        articleList: response.data.list,
                        initLoading: false
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    delete = id => {
        const that = this;
        this.setState({
            initLoading: true
        })
        instance.delete('/api/articlesServer/article/delete/' + id)
            .then(function (response) {
                if (response.data.msg === "success") {
                    notification.open({
                        message: '提示',
                        description:
                            '删除成功'
                    });
                    that.loadList();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    toList = () => {
        this.setState({
            showEdit: false,
            initLoading: true
        })
        this.loadList();
    }

    edit = id => {
        const that = this;
        this.setState({
            initLoading: true
        })
        instance.post('/api/articlesServer/article/getforEdit',
            { id: id }
        )
            .then(function (response) {
                if (response.data.msg === "success") {
                    that.setState({
                        editInfo: response.data.info,
                        showEdit: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { initLoading, articleList, showEdit, editInfo } = this.state;

        return (
            <div>
                {
                    showEdit ?
                        <PublishEdit isEdit={true} editInfo={editInfo} toList={this.toList}></PublishEdit>
                        :
                        <div className="blocks">
                            <div className="model-title">Article List</div>
                            <List
                                className="demo-loadmore-list"
                                loading={initLoading}
                                itemLayout="horizontal"
                                dataSource={articleList}
                                pagination={{
                                    onChange: page => {
                                        console.log(page);
                                    },
                                    pageSize: 6,
                                    hideOnSinglePage: true
                                }}
                                renderItem={item => (
                                    <List.Item
                                        // eslint-disable-next-line
                                        actions={[<a onClick={() => {
                                            this.edit(item.id)
                                            // eslint-disable-next-line
                                        }} key="list-loadmore-edit">Edit</a>, <a onClick={() => {
                                            this.delete(item.id)
                                        }} key="list-loadmore-more">Delete</a>]}
                                    >
                                        <List.Item.Meta
                                            // eslint-disable-next-line
                                            title={<a>{item.title}</a>}
                                            description={item.date}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                }
            </div>
        );
    }
}

export default ArticleList;