import React, { Component } from 'react';
import { Avatar, Layout, Menu, Icon } from 'antd';
import './App.css';
import Selectors from './components/Selectors/Selectors';
import Lists from './components/Lists/Lists';
import Login from './components/Login/Login';
import rdLogin from './redux/rdLogin';
import rpwleft from './rpwlogocoloricon.png';
import rpwright from './rpwright.png';
import CMSPage from './components/CMS/CMSPage';
import PublishEdit from './components/Content/PublishEdit';
import ArticleList from './components/Content/ArticleList';
const { Header, Sider } = Layout;
const { SubMenu } = Menu;

class App extends Component {
  state = {
    collapsed: false,
    //loginStatus: rdLogin.loginStore.getState().loginStatus,
    loginStatus: false,
    currentSelection: 'members',
    searchInfo: {
      search: '',
      forSearch: false,
      selectors: {
        applyType: '',
        event: '',
        memberEmail: '',
        memberCompany: '',
        memberJobTitle: '',
        memberLevel: '',
        memberMobile: '',
        memberName: ''
      }
    }
  };
  componentDidMount() {
    //更新登陆状态信息
    rdLogin.loginStore.subscribe(() => {
      this.setState({
        loginStatus: rdLogin.loginStore.getState().loginStatus
      })
    })
  }

  //设置搜索框搜索
  search = (value) => {
    this.setState({
      searchInfo: {
        search: value,
        forSearch: true,
        selectors: {
          applyType: '',
          event: '',
          memberEmail: '',
          memberJobTitle: '',
          memberLevel: '',
          memberMobile: '',
          memberName: ''
        }
      }
    }, () => {
      console.log(this.state.searchInfo)
    })
  }

  //设置条件框搜素
  selector = (value) => {
    this.setState({
      searchInfo: {
        search: '',
        forSearch: false,
        selectors: value
      }
    }, () => {
      console.log(this.state.searchInfo)
    })
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/*登陆模块*/}
        <Login loginStatus={this.state.loginStatus}></Login>
        {/*侧边栏*/}
        <Sider style={this.state.loginStatus ? {} : { display: 'none' }} trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className='logo-wrap'>
            <img src={rpwleft} alt='rpwLogo' className='logo-small' />
            <img src={rpwright} alt='rpwLogo' className={this.state.collapsed ? 'none' : 'logo-right'} />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={
            (item) => {
              this.setState({
                currentSelection: item.key
              })
            }
          }>
            <Menu.Item key="members">
              <Icon type="user" />
              <span>Members</span>
            </Menu.Item>
            <SubMenu
              key="CMS"
              title={
                <span>
                  <Icon type="book" />
                  <span>CMS</span>
                </span>
              }
            >
              <Menu.Item key="rpwchina">RPWChina</Menu.Item>
              <Menu.Item key="rpwthailand">RPWThailand</Menu.Item>
              <Menu.Item key="rpwasia">RPWAsia</Menu.Item>
              <Menu.Item key="rpwchinaCN">RPWChinaCN</Menu.Item>
            </SubMenu>
            <SubMenu
              key="content"
              title={
                <span>
                  <Icon type="book" />
                  <span>Content</span>
                </span>
              }
            >
              <Menu.Item key="publish">Publish</Menu.Item>
              <Menu.Item key="contentManagement">Management</Menu.Item>
            </SubMenu>
            <SubMenu
              key="network"
              title={
                <span>
                  <Icon type="deployment-unit" />
                  <span>1-2-1</span>
                </span>
              }
            >
              <Menu.Item key="meetings">Meetings</Menu.Item>
              <Menu.Item key="cards">Cards</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={this.state.loginStatus ? {} : { display: 'none' }}>
          {/*收缩按钮与头像*/}
          <Header style={{ background: '#fff', padding: 0 }} className="flex-between-center">
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <div className="flex-between-center" style={{ paddingRight: '24px' }}>
              <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large">
                {rdLogin.loginStore.getState().account.substr(0, 1).toUpperCase()}
              </Avatar>
              <div style={{ paddingLeft: '10px' }}>{rdLogin.loginStore.getState().account}</div>
            </div>
          </Header>
          {/*Member*/}
          <div style={this.state.currentSelection === 'members' ? {} : { display: 'none' }}>
            <div
              style={{
                margin: '24px 16px',
                padding: 24,
                background: '#fff'
              }}
            >
              {/*Member查询区*/}
              <Selectors search={this.search} selector={this.selector}></Selectors>
            </div>
            <div
              style={{
                margin: '0 16px'
              }}
            >
              {/*Member列表*/}
              <Lists searchInfo={this.state.searchInfo}></Lists>
            </div>
          </div>
          {/*CMS*/}
          {
            this.state.currentSelection === "rpwchina" || this.state.currentSelection === "rpwthailand" || this.state.currentSelection === "rpwasia" || this.state.currentSelection === "rpwchinaCN"
              ? <CMSPage event={this.state.currentSelection}></CMSPage>
              : <div></div>
          }
          {/*Content Publish & Edit*/}
          {
            this.state.currentSelection === "publish"
              ? <PublishEdit></PublishEdit>
              : <div></div>
          }
          {/*Article & Video List*/}
          {
            this.state.currentSelection === "contentManagement"
              ? <ArticleList></ArticleList>
              : <div></div>
          }
        </Layout>
      </Layout>
    );
  }
}

export default App;