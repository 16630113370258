import React, { Component } from 'react';
import { List, Avatar, Button, Skeleton, Icon, notification } from 'antd';
import AddSponsors from './AddSponsors';
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});

class SponsorList extends Component {
    state = {
        SponsorsInfo: [],
        initLoading: true,
        loading: false,
        loadMoreStatus: true,
        isAddEdit: false,
        isAdd: true,
        editInfo: {},
        logoUrl: '',
        collapse: true,
        event: this.props.event,
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.event !== this.state.event) {
            this.setState({
                event: nextProps.event,
            });
            this.getSponsors(3, nextProps.event);
        }
    }
    componentDidMount() {
        const { event } = this.state;
        this.getSponsors(3, event);
    };
    //获取赞助商信息
    getSponsors = (limit, event) => {
        const that = this;
        this.setState({
            initLoading: true
        })
        instance.get('/api/cmsServer/sponsor/list?limit=' + limit + '&event=' + event)
            .then(function (response) {
                if (response.data.msg === "success") {
                    that.setState({
                        SponsorsInfo: response.data.list,
                        initLoading: false
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    //打开添加页
    addOpen = () => {
        this.setState({
            isAddEdit: true,
            isAdd: true,
            editInfo: {},
            isOpenClose: true
        }, () => {
            this.setState({
                isOpenClose: false
            })
        });
    }
    //关闭添加页
    addClose = () => {
        this.setState({
            isAddEdit: false,
            logoUrl: '',
            isOpenClose: true
        }, () => {
            this.setState({
                isOpenClose: false
            })
        })
    }
    //打开编辑页
    invokeSponsorEdit = (item) => {
        console.log(item)
        this.setState({
            isAddEdit: true,
            isAdd: false,
            editInfo: item,
            logoUrl: item.logoUrl,
            isOpenClose: true
        }, () => {
            this.setState({
                isOpenClose: false
            })
        })
    }
    //删除赞助商
    delete = (id) => {
        const that = this;
        const { event } = this.state;
        instance.delete('/api/cmsServer/sponsor/' + id)
            .then(function (response) {
                if (response.data.msg === "success") {
                    notification.open({
                        message: '提示',
                        description:
                            '删除成功'
                    });
                    that.getSponsors('', event);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    //折叠
    folder = () => {
        this.setState({
            collapse: this.state.collapse ? false : true
        })
    }
    render() {
        const { initLoading, SponsorsInfo, loading, loadMoreStatus, collapse, event } = this.state;
        const loadMore =
            !initLoading && !loading && loadMoreStatus ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={() => { this.getSponsors('', event) }}>loading more</Button>
                </div>
            ) : null;
        return (
            <div>
                <div className='flex-align-center' style={{ marginBottom: 20 }}>
                    <div className="model-title">Sponsors</div>
                    <Button type="primary" onClick={() => { this.addOpen() }} style={collapse ? { marginRight: 20 } : { display: 'none' }}>
                        <Icon type="user-add" />Add
                    </Button>
                    <Icon type={collapse ? "up" : "down"} style={{ cursor: 'pointer' }} onClick={() => { this.folder() }} />
                </div>
                <AddSponsors isAddEdit={this.state.isAddEdit} isAdd={this.state.isAdd} editInfo={this.state.editInfo} addOpen={this.addOpen} addClose={this.addClose} reload={() => { this.getSponsors('', event) }} logoUrl={this.state.logoUrl} isOpenClose={this.state.isOpenClose} event={event}></AddSponsors>
                <List
                    style={collapse ? { minHeight: 290 } : { display: 'none' }}
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={SponsorsInfo}
                    renderItem={item => (
                        // eslint-disable-next-line
                        <List.Item actions={[<a onClick={() => { this.invokeSponsorEdit(item) }}>Edit</a>, <a onClick={() => { this.delete(item._id) }}>Delete</a>]}>
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={item.logoUrl} />
                                    }
                                    // eslint-disable-next-line
                                    title={<a>{item.name}</a>}
                                    description={item.type}
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}
export default SponsorList;