import React, { Component } from 'react';
import { Form, Row, Col, Input, Button, Select } from 'antd';
const { Search } = Input;
const { Option } = Select;

class AdvancedSearchForm extends Component {
    state = {
    };

    //查询表格框架
    getFields() {
        const fields = [{
            type: 'Name',
            name: 'memberName'
        }, {
            type: 'Email',
            name: 'memberEmail'
        }, {
            type: 'Company',
            name: 'memberCompany'
        }, {
            type: 'Job Title',
            name: 'memberJobTitle'
        }, {
            type: 'Mobile',
            name: 'memberMobile'
        }];
        const selectors = [{
            type: 'Apply Type',
            name: 'applyType',
            values: ['No Apply', 'Member', 'Delegate', 'Exhibitor', 'Sponsor', 'Download Post Report', 'Download Agenda']
        }, {
            type: 'Event',
            name: 'event',
            values: ['China2019', 'Thailand2020', 'Indonesia2020']
        }, {
            type: 'Member Level',
            name: 'memberLevel',
            values: ['Visitor', 'Member', 'Delegate', 'Exhibitor', 'Sponsor']
        }]
        const { getFieldDecorator } = this.props.form;
        const children = [];
        //循环建立表格
        const list = (array) => {
            var res = [];
            for (var i = 0; i < array.length; i++) {
                res.push(<Option key={array[i]} value={array[i]}>{array[i]}</Option>)
            }
            return res
        }
        for (let i = 0; i < fields.length; i++) {
            children.push(
                <Col span={6} key={`${fields[i].name}`} style={{ display: 'block' }}>
                    <Form.Item label={`${fields[i].type}`}>
                        {getFieldDecorator(`${fields[i].name}`, {})(<Input placeholder={`Please Input ${fields[i].type}`} />)}
                    </Form.Item>
                </Col>,
            );
        }
        for (let i = 0; i < selectors.length; i++) {
            children.push(
                <Col span={6} key={`${selectors[i].name}`} style={{ display: 'block' }}>
                    <Form.Item label={`${selectors[i].type}`}>
                        {getFieldDecorator(`${selectors[i].name}`, {})(<Select placeholder={`Please Select ${selectors[i].type}`}>
                            {list(selectors[i].values)}
                        </Select>)}
                    </Form.Item>
                </Col>,
            );
        }
        return children;
    }
    //传搜索数据进入App.js
    handleSearch = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            for (let i in values) {
                if (values[i] === undefined) {
                    values[i] = "";
                }
            }
            this.props.selector(values)
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    render() {
        return (
            <div className="form-wrap">
                <Search placeholder="Search from the Members" onSearch={(value) => { this.props.search(value) }} enterButton />
                <Form className="ant-advanced-search-form" onSubmit={this.handleSearch}>
                    <Row gutter={24}>
                        {this.getFields()}
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                            <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                                Clear
                     </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}
const WrappedAdvancedSearchForm = Form.create({ name: 'advanced_search' })(AdvancedSearchForm);

export default WrappedAdvancedSearchForm;