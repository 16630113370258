import React, { Component } from 'react';
import { Upload, Icon, message, Form, Input, Button, notification, Modal } from 'antd';
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://my-express-project-6ahfsb4pf.vercel.app/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
});

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}

class AddSpeaker extends Component {
    state = {
        loading: false,
        imgLoading: false,
        visible: this.props.isAddEdit,
        isAdd: this.props.isAdd,
        editInfo: this.props.editInfo,
        event: this.props.event
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenClose) {
            this.setState({
                visible: nextProps.isAddEdit,
                isAdd: nextProps.isAdd,
                editInfo: nextProps.editInfo,
                imageUrl: nextProps.imgUrl
            });
        }
        if (nextProps.event !== this.state.event) {
            this.setState({
                event: nextProps.event,
            });
        }
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            console.log(info)
            this.setState({ imgLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    imgLoading: false,
                    photoName: info.file.originFileObj.name
                }),
            );
        }
    };

    //添加演讲者
    speakerAdd = () => {
        const { imageUrl, photoName } = this.state;
        this.setState({
            loading: true
        });
        if (imageUrl) {
            const { form } = this.props;
            const that = this;
            form.validateFields((err, values) => {
                const that2 = this;
                if (err) {
                    return;
                }
                values.imgUrl = imageUrl;
                values.photoName = photoName;
                values.event = that.state.event
                instance.post('/api/cmsServer/speaker/add', {
                    speakerInfo: values
                })
                    .then(function (response) {
                        if (response.data.msg === "success") {
                            that.setState({
                                loading: false
                            });
                            form.resetFields();
                            that2.props.addClose();
                            that2.props.reload();
                            notification.open({
                                message: '提示',
                                description:
                                    '添加成功'
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
        } else {
            notification.open({
                message: '提示',
                description:
                    '请添加演讲者照片'
            });
        }
    }

    //编辑演讲者
    speakerEdit = () => {
        const { imageUrl, photoName } = this.state;
        this.setState({
            loading: true
        });
        const { form } = this.props;
        const that = this;
        form.validateFields((err, values) => {
            const that2 = this;
            if (err) {
                return;
            }
            values.imgUrl = imageUrl;
            values.photoName = photoName ? photoName : '';
            values._id = that.state.editInfo._id
            instance.post('/api/cmsServer/speaker/edit', {
                speakerInfo: values
            })
                .then(function (response) {
                    if (response.data.msg === "success") {
                        that.setState({
                            loading: false
                        });
                        form.resetFields();
                        that2.props.addClose();
                        that2.props.reload();
                        notification.open({
                            message: '提示',
                            description:
                                '编辑成功'
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.imgLoading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { imageUrl, visible } = this.state;
        const { addClose } = this.props;
        const { name, jobTitle, company, detail, sort } = this.state.isAdd ? '' : this.state.editInfo;
        return (
            <Modal
                visible={visible}
                title="Add Speaker"
                onOk={this.addSend}
                onCancel={addClose.bind(this)}
                footer={[
                    <Button key="back" onClick={addClose.bind(this)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={this.state.isAdd ? this.speakerAdd : this.speakerEdit}>
                        {this.state.isAdd ? 'Add' : 'Edit'}
                    </Button>
                ]}
                destroyOnClose={true}
            >
                <div>
                    <Upload
                        name="speakerPhoto"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action='https://my-express-project-6ahfsb4pf.vercel.app/api/cmsServer/uploadImg'
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} style={imageStyle} alt="avatar" /> : uploadButton}
                    </Upload>
                    <Form style={{ maxWidth: 500 }}>
                        <Form.Item style={{ marginBottom: '12px' }}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input speaker\'s Name',
                                    },
                                ],
                                initialValue: name
                            })(<Input placeholder="Please input speaker's Name" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }}>
                            {getFieldDecorator('jobTitle', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input speaker\'s jobTitle',
                                    },
                                ],
                                initialValue: jobTitle
                            })(<Input placeholder="Please input speaker's jobTitle" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }}>
                            {getFieldDecorator('company', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input speaker\'s company',
                                    },
                                ],
                                initialValue: company
                            })(<Input placeholder="Please input speaker's company" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }}>
                            {getFieldDecorator('detail', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input speaker\'s cv',
                                    },
                                ],
                                initialValue: detail
                            })(<Input placeholder="Please input speaker's cv" />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '12px' }}>
                            {getFieldDecorator('sort', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input speaker\'s sort',
                                    },
                                ],
                                initialValue: sort
                            })(<Input type='number' placeholder="Please input speaker's sort" />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}
const AddSpeakerForm = Form.create({ name: 'AddSpeaker' })(AddSpeaker);

const imageStyle = {
    width: 100,
    height: 85
}

export default AddSpeakerForm;